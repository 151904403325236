/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames/bind';

import Spinner from '@components/common/responsive/Spinner/Spinner';

import Button, { ButtonProps } from './Button';

import style from './ButtonAsLink.module.scss';

const setClass = classNames.bind(style);

interface ButtonAsLinkProps {
  className?: string;
  primary?: boolean;
  blackened?: boolean;
  white?: boolean;
  isUnderline?: boolean;
  isDisabled?: boolean;
}
const ButtonAsLink = ({
  className,
  primary = false,
  isUnderline = true,
  isDisabled = false,
  ...props
}: ButtonProps & ButtonAsLinkProps) => {
  return (
    <Button
      {...props}
      className={setClass(
        {
          asLink: true,
          isUnderline,
          disabled: isDisabled,
          primary,
        },
        className
      )}
      isDisabled={isDisabled}
      loader={<Spinner darkBlue />}
    />
  );
};

export default ButtonAsLink;
