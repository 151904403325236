import { normalizeMaxCharacterLength } from '@lib/normalizers/normalizeMaxCharacterLength';

const chainNormalizeFunctions = (
  normalizers: Array<
    (value: string, previousValue: string, maxLength: number) => string
  >,
  value: string,
  previousValue: string,
  maxLength: number
) => {
  const normalizationChain = [...normalizers, normalizeMaxCharacterLength];

  return normalizationChain.reduce(
    (acc, fn) => fn(acc, previousValue, maxLength),
    value
  );
};

export default chainNormalizeFunctions;
