import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const SearchIconLarge = ({ className, stroke, fill }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    stroke={stroke}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5403 18.8028C18.6351 17.0625 20.5438 12.3323 18.8036 8.23759C17.0633 4.14283 12.3332 2.23411 8.2384 3.97434C4.14364 5.71457 2.23492 10.4448 3.97515 14.5395C5.71538 18.6343 10.4456 20.543 14.5403 18.8028Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0853 17.0844L23.3333 23.3333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default asIcon(SearchIconLarge);
