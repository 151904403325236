import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import { FormikValues } from 'formik';

import { countFilteredValues, filterValues } from '@lib/filterValues';
import { setPriceFilterValues } from '@lib/setPriceFilters';

import {
  AdRoute,
  AllUserAdsRoute,
  CategorySearchRoute,
  GroupSearchRoute,
  HomeRoute,
  SearchRoute,
} from 'server/routes';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectSuggestedSearch } from '@store/classifier/selector';
import { selectCarValuesName } from '@store/customValue/selector';
import { selectGroupAdClass } from '@store/group/selector';
import { selectIsMobile, selectPageType } from '@store/meta/selector';
import {
  selectFiltersToRemove,
  selectFiltersToUpdate,
} from '@store/searchForm/selector';
import {
  removeSelectedFilters,
  setSearchDetailedOpen,
  updateSelectedFilters,
} from '@store/searchForm/slice';
import { selectUserSummary } from '@store/user/selector';

import CategoryName from '@components/category/responsive/CategoryName/CategoryName';
import GroupName from '@components/group/responsive/GroupName/GroupName';

import useSearchPlaceholder from './useSearchPlaceholder';

type UseSearchProps = Pick<FormikValues, 'values' | 'setFieldValue'>;

export const useSearch = ({ values, setFieldValue }: UseSearchProps) => {
  const dispatch = useAppDispatch();
  const didMountRef = useRef(false);
  const router = useRouter();
  const { query } = router;
  const isMobile = useAppSelector(selectIsMobile);
  const [submit, setSubmit] = useState(false);

  const suggestedSearch = useAppSelector(selectSuggestedSearch);
  const filtersToRemove = useAppSelector(selectFiltersToRemove);
  const filtersToUpdate = useAppSelector(selectFiltersToUpdate);
  const { username } = useAppSelector(selectUserSummary);
  const pageType = useAppSelector(selectPageType);
  const carValuesName = useAppSelector(selectCarValuesName);
  const groupAdClass = useAppSelector((state) =>
    selectGroupAdClass(state, {
      categoryId: values.categoryId,
      groupId: values.groupId,
    })
  );

  const { searchPlaceholder } = useSearchPlaceholder({ values });

  const categoryName = CategoryName({ categoryId: values.categoryId });
  const isAllUserAdsPage = pageType === 'allUserAds';
  const isGroupAdClassCar =
    (values.categoryId === 2013 && !values.groupId) || groupAdClass === 'car';

  const groupName = GroupName({
    categoryId: values.categoryId,
    groupId: values.groupId,
  });

  const selectedFilters = countFilteredValues(
    filterValues(values, ['keywords'])
  );

  const close = () => {
    dispatch(setSearchDetailedOpen(false));
  };

  const removeCarValuesFilters = () => {
    if (isGroupAdClassCar) {
      return {};
    }

    return carValuesName.reduce(
      (acc, elem) => {
        acc[elem] = '';
        return acc;
      },
      { carModel: '' }
    );
  };

  const getActiveOrder = (order) => {
    let activeOrder = '';
    if (order === 'newest') {
      activeOrder = 'posted desc';
    } else if (order) {
      activeOrder = order;
    }

    return activeOrder;
  };

  const search = () => {
    const { categoryId, groupId, keywords } = values;
    const canSearchByGroup = categoryId && groupId && groupName !== '...';

    const { order } = query;
    const activeOrder = getActiveOrder(order);

    const filteredValues: any = {
      ...filterValues(values),
      order: activeOrder,
      ...setPriceFilterValues(values, setFieldValue),
      ...removeCarValuesFilters(),
    };

    let userId = '';

    if (isAllUserAdsPage && !values?.ignoreUserId.includes('yes')) {
      userId = String(query.userId);
    }

    if (userId) {
      router.push(
        AllUserAdsRoute.generateUrl(
          {
            firstParam: username,
            userId,
            page: 1,
          },
          filteredValues
        )
      );
    } else if (keywords === `#${suggestedSearch?.[0]?.adId}`) {
      router.push(
        AdRoute.generateUrl({
          firstParam: suggestedSearch[0].categoryName,
          group: suggestedSearch[0].groupName,
          ad: suggestedSearch[0].adName,
          adId: suggestedSearch[0].adId,
        })
      );
    } else if (canSearchByGroup) {
      router.push(
        GroupSearchRoute.generateUrl(
          {
            firstParam: categoryName,
            group: groupName,
            groupId,
          },
          filteredValues
        )
      );
    } else if (categoryId && categoryName) {
      router.push(
        CategorySearchRoute.generateUrl(
          { firstParam: categoryName, categoryId },
          filteredValues
        )
      );
    } else if (countFilteredValues(filterValues(values)) === 0) {
      router.push(HomeRoute.generateUrl());
    } else {
      router.push(SearchRoute.generateUrl({}, filteredValues));
    }
  };

  const handleSubmitSearch = () => {
    setTimeout(search, 300);
    if (!isMobile) {
      close();
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (!isEmpty(filtersToRemove)) {
        filtersToRemove.forEach((filter: { name: string; value: string }) =>
          setFieldValue(filter, '')
        );
        dispatch(removeSelectedFilters([]));
        setSubmit(true);
      }
      if (!isEmpty(filtersToUpdate)) {
        filtersToUpdate.forEach((filter: { name: string; value: string }) => {
          setFieldValue(filter.name, filter.value);
        });
        dispatch(updateSelectedFilters([]));
        setSubmit(true);
      } else if (submit) {
        search();
        setSubmit(false);
      }
    } else {
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtersToRemove, filtersToUpdate, setFieldValue, submit]);

  return {
    searchPlaceholder,
    handleSubmitSearch,
    selectedFilters,
    close,
  };
};
