import { createSelector } from 'reselect';

import { classifierSelector } from '../rootSelector';
import { ClassifierInitialState } from './type';

export const selectIsLoading = createSelector(
  classifierSelector,
  (slicedState) => slicedState.isLoading
);

export const selectSuggestedSearch = createSelector(
  classifierSelector,
  (slicedState: ClassifierInitialState) => slicedState.suggestionsSearch
);

export const selectHoveredIndex = createSelector(
  classifierSelector,
  (slicedState: ClassifierInitialState) => slicedState.hoveredSuggestionIndex
);

export const selectSuggestedGroups = createSelector(
  classifierSelector,
  (slicedState: ClassifierInitialState) => slicedState.suggestions
);
