import { createSelector } from 'reselect';
import values from 'lodash/values';

import { customValueSelector } from '../rootSelector';
import { CustomValueInitialState, CustomValueSelectorPropType } from './type';

export const selectCarValues = createSelector(
  customValueSelector,
  (slicedState: CustomValueInitialState) => {
    try {
      return Object.fromEntries(
        Object.entries(slicedState.carValue).map(([k, v]) => [k, values(v)])
      );
    } catch (e) {
      return {};
    }
  }
);

export const selectCarValuesName = createSelector(
  customValueSelector,
  (slicedState: CustomValueInitialState) => {
    try {
      return Object.keys(slicedState.carValue);
    } catch (e) {
      return [];
    }
  }
);

export const selectCarValueName = createSelector(
  customValueSelector,
  (_, props) => props,
  (
    slicedState: CustomValueInitialState,
    props: CustomValueSelectorPropType
  ) => {
    try {
      const { property, id } = props;
      return property && id ? slicedState.carValue[property][id].name : '';
    } catch (e) {
      return '';
    }
  }
);
