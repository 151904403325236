import {
  CATEGORY_SEARCH_PLACEHOLDER,
  GROUP_SEARCH_PLACEHOLDER,
} from '@lib/constants/search.constants';

import getDefaultSearchPlaceholder from './getDefaultSearchPlaceholder';

type GetSearchPlaceholderProps = {
  totalAdCount: string;
  categoryId: string;
  groupId: string;
};

const getDynamicSearchPlaceholder = ({
  totalAdCount,
  categoryId,
  groupId,
}: GetSearchPlaceholderProps) => {
  if (groupId) {
    return GROUP_SEARCH_PLACEHOLDER;
  }

  if (categoryId) {
    return CATEGORY_SEARCH_PLACEHOLDER;
  }

  return getDefaultSearchPlaceholder(totalAdCount);
};

export default getDynamicSearchPlaceholder;
