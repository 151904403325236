/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames/bind';

import { COLOR_TYPES } from '@lib/constants/colors.constants';

import SearchIconLarge from '../../icons/common/SearchIconLarge';

import Button, { ButtonProps } from './Button';

import style from './ButtonSearch.module.scss';

const setClass = classNames.bind(style);

export interface ButtonSearchProps {
  className?: string;
}

const ButtonSearch = ({
  className,
  ...props
}: ButtonProps & ButtonSearchProps) => {
  return (
    <Button
      ariaLabel="Pretražite"
      {...props}
      className={setClass({ search: true }, className)}
    >
      <SearchIconLarge stroke={COLOR_TYPES.contentOnFill} />
    </Button>
  );
};

export default ButtonSearch;
