import { FormikValues } from 'formik';

import { ALL_USER_ADS_SEARCH_PLACEHOLDER } from '@lib/constants/search.constants';
import getDynamicSearchPlaceholder from '@lib/search/getDynamicSearchPlaceholder';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectSearchPlaceholder } from '@store/category/selector';
import { selectTotalAdCountByKind } from '@store/generalStats/selector';
import { selectPageType } from '@store/meta/selector';

type UseSearchPlaceholderProps = Pick<FormikValues, 'values'>;

const useSearchPlaceholder = ({
  values: { categoryId = '', groupId = '' },
}: UseSearchPlaceholderProps) => {
  const pageType = useAppSelector(selectPageType);
  const { totalAdCount } = useAppSelector(selectTotalAdCountByKind);
  const groupCategorySearchPlaceholder = useAppSelector((state) =>
    selectSearchPlaceholder(state, { categoryId, groupId })
  );

  const defaultSearchPlaceholder = getDynamicSearchPlaceholder({
    totalAdCount,
    categoryId,
    groupId,
  });

  const isAllUserAdsPage = pageType === 'allUserAds';

  const searchPlaceholder = isAllUserAdsPage
    ? ALL_USER_ADS_SEARCH_PLACEHOLDER
    : defaultSearchPlaceholder;

  return {
    searchPlaceholder: groupCategorySearchPlaceholder || searchPlaceholder,
  };
};

export default useSearchPlaceholder;
