import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

function useResolvedElement(subscriber, refOrElement) {
  let ref = useRef(null);
  const refElement = useRef(null);
  const callbackRefElement = useRef(null);
  const callbackRef = useCallback((element) => {
    callbackRefElement.current = element;
    callSubscriber();
  }, []);
  const lastReportedElementRef = useRef(null);
  const cleanupRef = useRef();
  const callSubscriber = () => {
    let element = null;
    if (callbackRefElement.current) {
      element = callbackRefElement.current;
    } else if (refElement.current) {
      element = refElement.current;
    } else if (refOrElement instanceof HTMLElement) {
      element = refOrElement;
    }
    if (lastReportedElementRef.current === element) {
      return;
    }
    if (cleanupRef.current) {
      cleanupRef.current();
    }
    lastReportedElementRef.current = element;

    if (element) {
      cleanupRef.current = subscriber(element);
    }
  };
  if (refOrElement && !(refOrElement instanceof HTMLElement)) {
    ref = refOrElement;
  }

  useEffect(() => {
    refElement.current = ref.current;
    callSubscriber();
  }, [ref, ref.current, refOrElement]);
  return {
    ref,
    callbackRef,
  };
}
function useResizeObserver(opts = {}) {
  const onResize = opts.onResize;
  const onResizeRef = useRef(undefined);
  onResizeRef.current = onResize;

  const resizeObserverRef = useRef();
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const didUnmount = useRef(false);
  useEffect(() => {
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const previous = useRef({
    width: undefined,
    height: undefined,
  });

  const { ref, callbackRef } = useResolvedElement((element) => {
    if (!resizeObserverRef.current) {
      resizeObserverRef.current = new ResizeObserver((entries) => {
        if (!Array.isArray(entries)) {
          return;
        }

        if (!entries.length) {
          return;
        }
        const entry = entries[0];

        const newWidth = Math.round(entry.contentRect.width);
        const newHeight = Math.round(entry.contentRect.height);
        if (
          previous.current.width !== newWidth ||
          previous.current.height !== newHeight
        ) {
          const newSize = { width: newWidth, height: newHeight };
          if (onResizeRef.current) {
            onResizeRef.current(newSize);
          } else {
            previous.current.width = newWidth;
            previous.current.height = newHeight;
            if (!didUnmount.current) {
              setSize(newSize);
            }
          }
        }
      });
    }
    resizeObserverRef.current.observe(element);
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.unobserve(element);
      }
    };
  }, opts.ref);
  return useMemo(
    () => ({ ref, callbackRef, width: size.width, height: size.height }),
    [ref, callbackRef, size ? size.width : null, size ? size.height : null]
  );
}
export default useResizeObserver;
