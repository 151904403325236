/* eslint-disable @typescript-eslint/no-unused-vars */
export const normalizeMaxCharacterLength = (
  value = '',
  previousValue = '',
  maxLength = null
) => {
  if (maxLength === null) {
    return value;
  }

  if (value?.length > maxLength) {
    return value.slice(0, maxLength);
  }

  return value;
};
