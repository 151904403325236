import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectGroupName } from '@store/group/selector';

const GroupName = ({ categoryId, groupId }) => {
  const groupName = useAppSelector((state) =>
    selectGroupName(state, { categoryId, groupId })
  );

  return groupName || '';
};

export default GroupName;
