/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import { FormikValues } from 'formik';
import classNames from 'classnames/bind';

import { COLOR_TYPES } from '@lib/constants/colors.constants';
import chainNormalizeFunctions from '@lib/input/chainNormalizeFunctions';
import { trimInputValue } from '@lib/input/trimInputValue';

import { useAppDispatch } from '@hooks/redux/useAppDispatch';
import { useAppSelector } from '@hooks/redux/useAppSelector';
import useResizeObserver from '@hooks/UseResizeObserver';

import { selectFocusedFilter } from '@store/searchForm/selector';
import { setFocusedFilter } from '@store/searchForm/slice';

import Button from '@components/controls/Button/Button';
import CloseIconSmall from '@components/icons/functions/CloseIconSmall';

import style from './Input.module.scss';

const setClass = classNames.bind(style);

export type InputProps = {
  label?: string;
  subLabel?: string;
  type?: string;
  placeholder?: string;
  autoFocus?: boolean;
  normalizers?: Array<
    (value: string, previousValue: string, maxLength?: number) => string
  >;
  displayError?: boolean;
  isClearable?: boolean;
  maxLength?: number;
  className?: string;
  trailing?: React.ReactNode;
  trailingInner?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: React.ClipboardEventHandler<HTMLInputElement>;
  validateOnBlur?: boolean;
  affects?: string;
  autoComplete?: string;
  onDrop?: (e: React.DragEvent<HTMLInputElement>) => void;
  showCounter?: boolean;
  readOnly?: boolean;
  isDisabled?: boolean;
  onClear?: () => void;
} & FormikValues;

const Input = ({
  label = '',
  subLabel = '',
  type = 'text',
  placeholder = '',
  autoFocus = false,
  field: { name, value: passedValue },
  form: { errors, touched, setFieldValue, handleBlur, setFieldError },
  normalizers = [],
  displayError = false,
  isClearable = false,
  maxLength = 100,
  className = '',
  trailing = null,
  trailingInner = null,
  onClick = null,
  onChange = null,
  onKeyDown = null,
  onPaste = null,
  validateOnBlur = false,
  affects = '',
  autoComplete = 'off',
  onDrop = null,
  showCounter = false,
  readOnly = false,
  isDisabled = false,
  onBlur = null,
  onClear = null,
}: InputProps) => {
  const inputEl = useRef(null);
  const dispatch = useAppDispatch();
  const focusedFilter = useAppSelector(selectFocusedFilter);
  const trailingWidthDefault = trailing ? 44 : 0;
  const { ref: trailingRef, width: trailingWidth = trailingWidthDefault } =
    useResizeObserver();
  const { ref: trailingInnerRef, width: trailingInnerWidth = 0 } =
    useResizeObserver();
  const [value, setValue] = useState('');
  const [t, setT] = useState(undefined);

  useEffect(() => {
    if (!readOnly && (autoFocus || focusedFilter === name)) {
      inputEl?.current.focus();
      dispatch(setFocusedFilter(''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus, focusedFilter, name]);

  useEffect(() => {
    if (passedValue !== value) {
      setValue(passedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedValue]);

  const clearValue = () => {
    setFieldValue(name, '');
    setValue('');
    inputEl?.current.focus();

    if (onClear) onClear();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = chainNormalizeFunctions(
      normalizers,
      event.target.value,
      value,
      maxLength
    );

    setValue(newValue);

    if (t) clearTimeout(t);

    setT(
      setTimeout(() => {
        setFieldValue(name, newValue);
        if (affects) {
          setFieldValue(affects, '');
        }
        if (onChange) {
          onChange(event, newValue);
        }
      }, 1)
    );
  };

  const onBlurChange = (event: any) => {
    const trimmedValue = trimInputValue(value);
    setFieldValue(name, trimmedValue);
    setFieldError(name, '');
    if (validateOnBlur) handleBlur(event);
    if (onBlur) onBlur(event);
  };

  const handleonDrop = (event: React.DragEvent<HTMLInputElement>) => {
    if (onDrop) {
      onDrop(event);
      return;
    }
    const newValue = `${value}${event.dataTransfer.getData('text')}`;
    const normalizedValue = chainNormalizeFunctions(
      normalizers,
      newValue,
      value,
      maxLength
    );

    setValue(normalizedValue);
  };

  const isTouched = touched[name];

  return (
    <section
      className={setClass(
        {
          inputHolder: true,
          inputError: errors[name] && isTouched,
          isClearable,
          isTrailingInner: trailingInner,
        },
        className
      )}
    >
      {label && (
        <label className={setClass({ label: true, isDisabled })} htmlFor={name}>
          {label}
          {subLabel && <span className={style.subLabel}>&nbsp;{subLabel}</span>}
          {showCounter && (
            <span
              className={style.counter}
            >{`(${value.length}/${maxLength})`}</span>
          )}
        </label>
      )}
      <div className={setClass({ inputRow: true, label, isDisabled })}>
        <input
          aria-label={name}
          data-hj-allow=""
          autoCorrect="off"
          autoCapitalize="none"
          data-test={name}
          id={name}
          type={type}
          value={value}
          name={name}
          onChange={handleChange}
          onBlur={onBlurChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          onDrop={handleonDrop}
          onPaste={onPaste}
          placeholder={placeholder}
          maxLength={maxLength}
          ref={inputEl}
          autoComplete={autoComplete}
          readOnly={readOnly || isDisabled}
          style={{
            paddingRight: `${trailingInnerWidth + (isClearable ? 26 : 8)}px`,
          }}
          error={errors[name] && isTouched && name}
        />
        {trailingInner && (
          <span
            ref={trailingInnerRef}
            className={style.trailingInner}
            style={{ marginRight: `${trailingWidth}px` }}
          >
            {trailingInner}
          </span>
        )}
        {trailing && <span ref={trailingRef}>{trailing}</span>}
        {isClearable && value && (
          <Button
            className={style.clearHolder}
            onClick={clearValue}
            style={{ marginRight: `${trailingWidth + trailingInnerWidth}px` }}
          >
            <span className={style.clear} data-name="clear">
              <CloseIconSmall
                stroke={COLOR_TYPES.contentInteractiveSecondary}
              />
            </span>
          </Button>
        )}
      </div>
      {displayError && (
        <span className={style.error}>
          {isTouched && errors[name] && errors[name]}
        </span>
      )}
    </section>
  );
};

export default Input;
