import { FormikHelpers, FormikValues } from 'formik';

export const setPriceFilterValues = (
  { priceFrom, priceTo, currency },
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue']
) => {
  const priceFilters = {
    priceFrom,
    priceTo,
    currency,
  };

  if (Number(priceFrom) > Number(priceTo)) {
    priceFilters.priceFrom = priceTo;
    priceFilters.priceTo = priceFrom;
  }

  if (!Number(priceTo)) {
    priceFilters.priceFrom = priceFrom;
    priceFilters.priceTo = '';
  }

  if ((priceFrom || priceTo) && !currency) {
    priceFilters.currency = 'rsd';
  }

  if (setFieldValue) {
    setFieldValue('currency', priceFilters.currency);
    setFieldValue('priceFrom', priceFilters.priceFrom);
    setFieldValue('priceTo', priceFilters.priceTo);
  }

  return priceFilters;
};
