import { Any } from '@store/global/type';

interface Value {
  [key: string]: unknown;
}

export const filterValues = (values: Value, additionalValues: string[] = []) =>
  Object.keys(values).reduce((object, key) => {
    if (
      ![
        'filterId',
        'prev_keywords',
        'ignoreUserId',
        'userId',
        'kind',
        'groupIdQuery',
        'categoryIdQuery',
        'locationIdQuery',
        'carModelQuery',
        ...additionalValues,
      ].includes(key)
    ) {
      object[key] = values[key];
    }
    return object;
  }, {});

export const countFilteredValues = (filteredValues: { [key: string]: Any }) =>
  Object.values(filteredValues).filter((v) => {
    return typeof v === 'object' ? v.length : v;
  }).length;
