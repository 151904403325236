import React from 'react';

import { IconProps } from '@type/icons';

import asIcon from '../asIcon';

const CloseIconSmall = ({ className, stroke, fill }: IconProps) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill={fill}
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 7L7 1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7L1 1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default asIcon(CloseIconSmall);
