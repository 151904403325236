import { selectCategoryName } from '@store/category/selector';
import { useAppSelector } from '@hooks/redux/useAppSelector';

type CategoryNameProps = {
  categoryId: string | number;
};

const CategoryName = ({ categoryId }: CategoryNameProps) => {
  const categoryName = useAppSelector((state) =>
    selectCategoryName(state, categoryId)
  );

  return !categoryName ? '...' : categoryName;
};

export default CategoryName;
